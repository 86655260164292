[data-theme-2="dbp"] {

  * {
    font-family: "NimbusSansRound", sans-serif !important;
    letter-spacing: normal !important;
  }

  .ef-article-card__media:before,
  .ef-article-card__media:after,
  .ef-newsflash-card__media:before,
  .ef-newsflash-card__media:after,
  .ef-teaser-card__media:before,
  .ef-teaser-card__media:after,
  .ef-product-card__media:before,
  .ef-product-card__media:after,
  .ef-video-card__media:before,
  .ef-video-card__media:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .ef-article-card__media:before,
  .ef-newsflash-card__media:before,
  .ef-teaser-card__media:before,
  .ef-product-card__media:before,
  .ef-video-card__media:before {
    top: -1px;
    left: -1px;
    border-width: spacing(2.5) spacing(2.5) 0 0;
    border-color: white transparent transparent transparent;
  }

  &[data-theme="dark"] .ef-article-card__media:before,
  &[data-theme="dark"] .ef-newsflash-card__media:before,
  &[data-theme="dark"] .ef-teaser-card__media:before,
  &[data-theme="dark"] .ef-product-card__media:before,
  &[data-theme="dark"] .ef-video-card__media:before {
    border-color: black transparent transparent transparent;
  }

  .ef-article-card__media:after,
  .ef-newsflash-card__media:after,
  .ef-teaser-card__media:after,
  .ef-product-card__media:after,
  .ef-video-card__media:after {
    right: -1px;
    bottom: -1px;
    border-width: 0 0 spacing(2.5) spacing(2.5);
    border-color: transparent transparent white transparent;
  }

  &[data-theme="dark"] .ef-article-card__media:after,
  &[data-theme="dark"] .ef-newsflash-card__media:after,
  &[data-theme="dark"] .ef-teaser-card__media:after,
  &[data-theme="dark"] .ef-product-card__media:after,
  &[data-theme="dark"] .ef-video-card__media:after {
    border-color: transparent transparent black transparent;
  }
}

[data-theme-2="dbp"],
[data-theme-2="waldstadion"] {

  .ef-header__logo {
    width: calc(#{spacing-no-calc(4)} / 23 * 121);
    height: spacing(4);
    background-image: url(../../assets/img/dbp/logo_dbp-left.svg);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;

    @include break(tablet) {
      width: auto;
      height: auto;
      background: transparent;
    }
  }

  .ef-menu * {
    font-family: font($font--primary) !important;
  }

  .ef-primary-menu__items-toggle-label {
    font-family: font($font--subline) !important;
  }

  h1, h2, h3, h4, h5, h6,
  .ef-heading {
    font-weight: 700;
    hyphens: none;
  }

  .ef-kicker {
    @include type-scale(300);
    margin-bottom: 0;
    padding-left: 0;
    font-weight: 400;
    text-transform: none;

    &:before {
      display: none;
    }
  }

  .ef-button,
  .ef-link-button,
  .cm-button {
    font-weight: 700 !important;
  }

  blockquote,
  .ef-blockquote {
    max-width: none;
    padding: 0;

    &:before,
    &:after {
      display: none;
    }

    p,
    &__quote {
      @include type-scale(700);
      padding: 0;
      font-weight: 700;
      font-style: normal;
      text-transform: none;
      hyphens: auto;

      @include break(tablet) {
        hyphens: none;
      }
    }

    cite,
    &__cite {
      @include type-scale(400);
      padding-top: spacing(4);
      padding-left: 0;
      text-align: left;
    }

    p:before,
    p:after,
    &__quote:before,
    &__quote:after {
      display: none;
    }
  }

  .ef-link-button {

    &:before,
    &:after {
      height: 1px;
    }

    &--back {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="black" d="M8.707.293a1,1,0,0,0-1.414,0l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,0,0,1.414-1.414L4.414,6,8.707,1.707A1,1,0,0,0,8.707.293Z" /%3E%3C/svg%3E');
    }

    &--more {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="black" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
    }

    &--more-top,
    &--more-bottom.--is-active {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="black"><path d="M6.707,2.293a1,1,0,0,0-1.414,0l-5,5A1,1,0,0,0,1.707,8.707L6,4.414l4.293,4.293a1,1,0,0,0,1.414-1.414Z"></path></g></svg>');
    }

    &--more-bottom {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="black"><path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z"></path></g></svg>');
    }

    &--logout {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="black"><polygon points="7 5 4 5 4 7 7 7 7 10 12 6 7 2 7 5" data-color="color-2"></polygon> <path d="M2,2H6V0H2A2,2,0,0,0,0,2v8a2,2,0,0,0,2,2H6V10H2Z"></path></g></svg>');
    }

    &--ticket {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"><g fill="black"><path fill-rule="evenodd" d="M.65 16.257l1.433 1.432c1.181-1.181 3.097-1.181 4.278 0 1.181 1.181 1.181 3.097 0 4.278L7.793 23.4c.39.39 1.023.39 1.414 0l8.392-8.392c.39-.39.39-1.023 0-1.414l-7.142-7.142c-.39-.39-1.024-.39-1.414 0L.65 14.843c-.39.39-.39 1.024 0 1.414zM11.736 5.173l7.142 7.142c.39.39 1.023.39 1.414 0l3.058-3.058c.39-.39.39-1.024 0-1.414L21.917 6.41c-1.181 1.181-3.097 1.181-4.278 0-1.181-1.181-1.181-3.097 0-4.278L16.207.7c-.39-.39-1.023-.39-1.414 0l-3.058 3.058c-.39.39-.39 1.024 0 1.414z"></path></g></svg>');
    }
  }

  &[data-theme="dark"] .ef-link-button {

    &--back {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="white" d="M8.707.293a1,1,0,0,0-1.414,0l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,0,0,1.414-1.414L4.414,6,8.707,1.707A1,1,0,0,0,8.707.293Z" /%3E%3C/svg%3E');
    }

    &--more {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="white" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
    }

    &--more-top,
    &--more-bottom.--is-active {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="white"><path d="M6.707,2.293a1,1,0,0,0-1.414,0l-5,5A1,1,0,0,0,1.707,8.707L6,4.414l4.293,4.293a1,1,0,0,0,1.414-1.414Z"></path></g></svg>');
    }

    &--more-bottom {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="white"><path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z"></path></g></svg>');
    }

    &--logout {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"><g fill="white"><polygon points="7 5 4 5 4 7 7 7 7 10 12 6 7 2 7 5" data-color="color-2"></polygon> <path d="M2,2H6V0H2A2,2,0,0,0,0,2v8a2,2,0,0,0,2,2H6V10H2Z"></path></g></svg>');
    }

    &--ticket {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"><g fill="white"><path fill-rule="evenodd" d="M.65 16.257l1.433 1.432c1.181-1.181 3.097-1.181 4.278 0 1.181 1.181 1.181 3.097 0 4.278L7.793 23.4c.39.39 1.023.39 1.414 0l8.392-8.392c.39-.39.39-1.023 0-1.414l-7.142-7.142c-.39-.39-1.024-.39-1.414 0L.65 14.843c-.39.39-.39 1.024 0 1.414zM11.736 5.173l7.142 7.142c.39.39 1.023.39 1.414 0l3.058-3.058c.39-.39.39-1.024 0-1.414L21.917 6.41c-1.181 1.181-3.097 1.181-4.278 0-1.181-1.181-1.181-3.097 0-4.278L16.207.7c-.39-.39-1.023-.39-1.414 0l-3.058 3.058c-.39.39-.39 1.024 0 1.414z"></path></g></svg>');
    }
  }

  .ef-primary-menu .ef-link-button--back { 
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="dark" d="M8.707.293a1,1,0,0,0-1.414,0l-5,5a1,1,0,0,0,0,1.414l5,5a1,1,0,0,0,1.414-1.414L4.414,6,8.707,1.707A1,1,0,0,0,8.707.293Z" /%3E%3C/svg%3E');
  }

  .ef-image-and-text .ef-link-button--more {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="white" d="M11.707,5.293,7,.586,5.586,2l3,3H0V7H8.586l-3,3L7,11.414l4.707-4.707A1,1,0,0,0,11.707,5.293Z" /%3E%3C/svg%3E');
  }

  .ef-accordion__header:after {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"%3E%3Cpath fill="black" d="M12,18c-0.256,0-0.512-0.098-0.707-0.293L0.586,7L2,5.586l10,10l10-10L23.414,7L12.707,17.707 C12.512,17.902,12.256,18,12,18z" /%3E%3C/svg%3E');
  }

  &[data-theme="dark"] .ef-accordion__header:after {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"%3E%3Cpath fill="white" d="M12,18c-0.256,0-0.512-0.098-0.707-0.293L0.586,7L2,5.586l10,10l10-10L23.414,7L12.707,17.707 C12.512,17.902,12.256,18,12,18z" /%3E%3C/svg%3E');
  }

  .ef-card:before,
  .ef-card:after {
    display: none;
  }

  .ef-video-card {

    &__icon {
      color: white;
    }

    @include break(desktop-s) {

      &__media:hover .ef-video-card__icon {
        color: white;
      }
    }

    &__kicker,
    .ef-heading,
    .ef-heading span {
      color: black;
    }
  }

  &[data-theme="dark"] .ef-video-card__kicker,
  &[data-theme="dark"] .ef-video-card .ef-heading,
  &[data-theme="dark"] .ef-video-card .ef-heading span {
    color: white;
  }

  .ef-section-header {
    margin-bottom: spacing(4);
  }

  .ef-section-header__more {
    position: relative;
    padding-left: spacing(3.222222);
  }

  .ef-section-header__more:before {
    content: "";
    position: absolute;
    top: spacing(.638889);
    bottom: spacing(.638889);
    left: spacing(1);
    width: 1px;
    padding-right: 0;
    background: black;
    transform: rotate(45deg);
  }

  &[data-theme="dark"] .ef-section-header__more:before {
    background: white;
  }

  .slick-slide {

    @include break(tablet) {
      padding-right: spacing(3);
    }
  }

  .ef-gallery-slider--dots .slick-slide {
    padding-right: 0;
  }

  .ef-card-grid {
    grid-column-gap: spacing(3);
  }

  .ef-product-card__media:before,
  .ef-product-card__media:after{
    @include transition();
  }
  .ef-product-card:hover .ef-product-card__media:before {
    left: calc(100% * 0.025 * -1 - 1px);
    top: calc(100% * 0.025 * -1 - 1px);
  }
  .ef-product-card:hover .ef-product-card__media:after {
    right: calc(100% * 0.025 * -1 - 1px);
    bottom: calc(100% * 0.025 * -1 - 1px);
  }

  .ef-teaser-card__media:after{
    top: auto;
    left: auto;
    z-index: 100;
  }

  .ef-teaser-card__media {
    background-image: linear-gradient(to top, rgba(18, 18, 18, 0.8), rgba(18, 18, 18, 0) 50%, transparent);
  }

  .ef-teaser-card__image {
    mix-blend-mode: multiply;
  }

  .ef-page {
    padding-top: spacing(10);

    .ef-gallery-modal {
      padding-top: spacing(10);
    }

    .ef-content {
      min-height: calc(100vh - #{spacing-no-calc(10)});
    }

    &.--has-submenu,
    &.--has-submenu .ef-gallery-modal {
      padding-top: spacing(16.5);

      .ef-content {
        min-height: calc(100vh - #{spacing-no-calc(16.5)});
      }
    }
  }

  .ef-header {
    border: none;
    background: black;
  }

  .ef-header__items--primary {
    padding-right: spacing(2);

    @include break(tablet) {
      padding-right: spacing(16);
    }
  }

  .ef-header .ef-badge {
    color: black;
  }

  .ef-header__hamburger {
    height: spacing(10);
  }

  .ef-hamburger__icon:before,
  .ef-hamburger__icon:after,
  .ef-hamburger__icon-line {
    background-color: white !important;
  }

  .ef-menu__logo-link-mobile {
    margin-top: 0;
    background: black;
  }

  .ef-primary-menu {
    top: spacing(10);

    @include break(tablet) {
      top: 0;
    }

    &__cta-button,
    &__cta-button:hover {
      border-color: var(--color-red-400);
    }
  }

  .ef-secondary-menu__logo--dbp {
    width: 24px / 5px * 40px;
    height: 24px;
  }

  .ef-submenu__link:hover {
    @include break(desktop-s) {
      color: black;
    }
  }

  .ef-submenu__link.--is-current:after {
    background-color: black;
  }

  .ef-search {
    top: spacing(10);
  }

  .ef-footer {
    background: black;
  }

  .ef-footer__dbp-partnership {
    @include type-scale(200);
    margin-bottom: spacing(2);
    color: var(--tone-medium);
  }

  .ef-footer__dbp-logo-image {
    width: spacing(18.434687);
    height: spacing(8);
  }

  .ef-content__block--article-grid,
  .ef-content__block--newsflash-grid {

    .ef-container--l {
      @extend %ef-max-width-l-center;
    }
  }

  .ef-article-grid,
  .ef-newsflash-grid {

    @include break(desktop-m) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  .ef-newsflash-grid--small {

    @include break(desktop-m) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  .ef-content__expansion-item {
    background-color: #f3f3f3;
  }

  &[data-theme="dark"] .ef-content__expansion-item {
    background-color: var(--tone-back-750);
  }

  .ef-accordion__header:after {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24"%3E%3Cpath fill="black" d="M12,18c-0.256,0-0.512-0.098-0.707-0.293L0.586,7L2,5.586l10,10l10-10L23.414,7L12.707,17.707 C12.512,17.902,12.256,18,12,18z" /%3E%3C/svg%3E');
  }

  .ef-accordion__heading {
    @include type-scale(400);
    max-width: none;
    font-weight: 700;
  }

  .ef-content__block--events {
    padding-bottom: spacing(6);

    &:after {
      content: "";
      position: absolute;
      right: spacing(3);
      bottom: 0;
      left: spacing(3);
      height: 1px;
      background-color: #A5A5A8;

      @include break(tablet) {
        right: spacing(6);
        left: spacing(6);
      }

      @include break(desktop-s) {
        right: spacing(9);
        left: spacing(9);
      }
    }
  }

  .ef-events {

    @include break(desktop-s) {
      background: url(../img/dbp/pattern_arrow_left_grey.svg) no-repeat right center;
      background-size: spacing(38);
    }
  }

  .ef-content__header {
    padding-top: spacing(3);

    .ef-kicker {
      //@extend .ef-heading--1;
      @include type-scale(600);
      position: relative;
      z-index: 2;
      // margin-bottom: 0;
      // font-weight: 400;
    }

    .ef-heading--2 {
      @extend .ef-heading--1;
      position: relative;
      z-index: 1;
      min-height: spacing(15);

      @include break(desktop-s) {
        min-height: spacing(21);
      }
    }

    .ef-heading--2:before {
      content: "";
      position: absolute;
      z-index: -1;
      right: 0;
      top: calc(#{spacing-no-calc(-3)});
      left: 0;
      height: spacing(18);
      background: url(../img/dbp/pattern_arrow_right_grey.svg) no-repeat left top;
      background-size: contain;

      @include break(desktop-s) {
        height: spacing(24);
        background: url(../img/dbp/pattern_arrow_right_grey_lg.svg) no-repeat left top;
      }
    }
  }

  .ef-fullscreen-hero {
    margin-bottom: spacing(12) !important;
    overflow: visible;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      height: spacing(6);
      background: url(../img/dbp/pattern_header_grey.svg) no-repeat center;
      background-size: cover;
    }

    // &__media:after {
    //   top: 0 !important;
    //   background: rgba(0, 0, 0, .5);
    // }

    &__content{
        margin-top: 0;

      &:before {
        content: "";
        position: absolute;
        right: 0;
        bottom: calc(100% + #{spacing-no-calc(2)});
        left: 0;
        height: spacing(18);
        background: url(../img/dbp/pattern_arrow_right_white.svg) no-repeat left bottom;
        background-size: contain;

        @include break(desktop-s) {
          height: spacing(24);
          background: url(../img/dbp/pattern_arrow_right_white_lg.svg) no-repeat left bottom;
        }
      }
    }

    &__heading {
      margin-bottom: 0;
    }

    &__text {
      align-items: baseline;
      padding-top: spacing(1);
    }

    .ef-link-button {
      @extend .ef-button;
      @extend .ef-button--primary;
      @extend .ef-button--large;
      background-color: white;
      background-position: right spacing(3) center;
      color: black !important;

      &--more {
        background-image: none;
      }
    }

    .ef-link-button:before,
    .ef-link-button:after {
      display: none;
    }

    .ef-link-button:hover,
    .ef-link-button:visited{
      background-color: white !important;
    }
  }

  .ef-info-box {

    h1, h2, h3, h4, h5, h6 {
      font-weight: 700;
    }
  }

  .ef-content__block--grid-list .ef-ticket-card:hover {

    @include break(desktop-s) {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 12 12"><polygon fill="#{url-encoded-color($color--black)}" points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>');
    }
  }

  &[data-theme="dark"] .ef-content__block--grid-list .ef-ticket-card:hover {

    @include break(desktop-s) {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 12 12"><polygon fill="white" points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>');
    }
  }

  .ef-content__block--icon-grid .ef-container--l {
    @include max-width(l);
  }

  .ef-content__block--image-and-text {
    position: relative;
    background: black;
    background-size: cover;

    &:before,
    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:before {
      top: -1px;
      left: -1px;
      border-width: spacing(2.5) spacing(2.5) 0 0;
      border-color: white transparent transparent transparent;
    }

    &:after {
      right: -1px;
      bottom: -1px;
      border-width: 0 0 spacing(2.5) spacing(2.5);
      border-color: transparent transparent white transparent;
    }

    .ef-container__narrow-m {
      max-width: none;
    }

    .ef-container--l{
      @extend %ef-max-width-xl-center;
    }
  }

  .ef-image-and-text {
    display: flex;
    flex-direction: column;

    &__image {
      order: 1;
      position: relative;
      z-index: 1;
      float: none !important;
      margin: 0 spacing(-3) !important;
      overflow: hidden;

      @include break(tablet) {
        width: calc(100% + #{spacing-no-calc(12)});
        margin: 0 spacing(-6) !important;
      }

      + .ef-image-and-text__text {
        order: 2;
      }
    }

    &__image--left {
      left: 0;
    }

    &__image--right {
      right: 0;
    }

    &__text {
      position: relative;
      order: 0;
      padding-top: spacing(6);
      padding-bottom: spacing(6);
    }

    .ef-caption {
      @extend %boxed-padding;

      @include break(desktop-s) {
        display: none;
      }
    }

    p a {
      @extend .ef-link-button;
      @extend .ef-link-button--more;
      text-decoration: none;
    }

    @include break(desktop-s) {
      flex-direction: row;
      align-items: center;
      min-height: 28.125vw;

      .ef-container {
        position: static;
      }

      &__image {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        margin: 0 !important;

        + .ef-image-and-text__text {
          margin-left: 50%;
          padding-left: spacing(9);
          padding-right: 0;
        }

        &--right {
          + .ef-image-and-text__text {
            margin-left: 0;
            padding-left: spacing(0);
            padding-right: spacing(9);
          }
        }

      }

      .ef-container__narrow-m,
      .ef-media {
        @include fill;
        height: 100%;
      }

      &__text {
        width: 50%;
        padding-top: spacing(12);
        padding-right: spacing(9);
        padding-bottom: spacing(12);
      }
    }
  }

  .ef-data-table__row{
    @include break(desktop-s) {

      &:nth-child(odd) {
        background-color: rgba($color--black, .05);
      }

      &:nth-child(even) {
        background-color: transparent;
      }
    }
  }

  .ef-teaser-card:hover .ef-teaser-card__heading span {
    color: var(--color-white);
  }

  .ef-launch-cta .ef-button {
    background-color: white;
    color: black;
  }

  .ef-logo-card__title {
    @include type-scale(500);
    text-transform: none;
  }

  .ef-logo-card__container {
    border-radius: 0;
    background-color: #f3f3f3;
  }

  .ef-logo-card__container:before,
  .ef-logo-card__container:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .ef-logo-card__container:before {
    top: -1px;
    left: -1px;
    border-width: spacing(2.5) spacing(2.5) 0 0;
    border-color: white transparent transparent transparent;
  }

  .ef-logo-card__container:after {
    right: -1px;
    bottom: -1px;
    border-width: 0 0 spacing(2.5) spacing(2.5);
    border-color: transparent transparent white transparent;
  }

  .ef-logo-grid {
    margin-bottom: spacing(8);
  }

  .ef-share__button {
    color: var(--color-back);
  }
}

body[data-theme-2="waldstadion"] {
  font-family: font($font--basic-sans);
  font-weight: 300;
}

[data-theme-2="waldstadion"] {
  .ef-copy-block h1,
  .ef-copy-block h2,
  .ef-copy-block h3,
  .ef-copy-block h4,
  .ef-copy-block h5,
  .ef-copy-block h6,
  .ef-heading,
  .ef-button {
    font-family: font($font--turnip) !important;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: uppercase;
  }

  .ef-kicker {
    @include type-scale(300);
    font-family: font($font--basic-sans);
    font-weight: 300;
    text-transform: none;
  }

  .ef-marquee__item,
  .ef-countdown__digits,
  .ef-stats-card__value {
    font-family: font($font--kabel) !important;
    font-weight: 400;
  }

  .ef-stats-card__value {
    @include type-scale(800);
  }

  .ef-countdown--l .ef-countdown__digits {
    @include type-scale(950);
  }

  .ef-countdown__label,
  .ef-stats-card__label {
    padding-top: spacing(1);
    font-family: font($font--turnip) !important;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: uppercase;
  }

  .ef-countdown__label {
    @include type-scale(400);
  }

  .ef-stats-card__label {
    @include type-scale(300);
    hyphens: auto;
  }

  blockquote p,
  .ef-featured-quote__quote {
    font-family: font($font--turnip) !important;
    font-weight: 300;
    font-style: italic;

    &:before {
      content: "„";
      display: inline;
    }

    &:after {
      content: "“";
      display: inline;
    }
  }

  .ef-fullscreen-hero:after {
    display: none;
  }

  .ef-fullscreen-hero__content:before {
    bottom: 0;
    background-image: url(../img/waldstadion/logo.svg);
  }

  .ef-fullscreen-hero__heading {
    display: none;
  }
}
