@keyframes ef-submenu-current {
  0% {
    transform: scaleX(0);
  } 100% {
    transform: none;
  }
}

span.ef-submenu__link:hover {
  color: var(--color-black);

  .ef-submenu--live & {
    color: var(--color-white);
  }
}

.ef-submenu {
  //display: none;
  //border-bottom: 1px solid var(--grayscale-200);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--color-white);
  //height: spacing(6);

  &--search {
    background-color: var(--tone-back-400);
  }

  &--filters {
    background-color: var(--tone-back-700);
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--light-gray-700);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 2px;
    width: spacing(12);
    pointer-events: none;
    background: linear-gradient(to left, $color--white, rgba($color--white, .001));
  }

  &--filters:after {

    @include theme(dark) {
      background: linear-gradient(to left, $dark-gray--700, rgba($dark-gray--700, .001));
    }
  }

  &--live:before,
  &--filters:before {
    display: none;
  }

  &--live:after {
    background: linear-gradient(to left, $color--red-400, rgba($color--red-400, .001));
  }

  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   width: spacing(9);
  //   background: linear-gradient(to left, $color--white, rgba($color--white, .001));
  // }

  &__inner {
    @extend %boxed-padding;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    &::-webkit-scrollbar {
      display: none;  /* Chrome Safari */
    }
  }

  .ef-container {
    display: flex;
    align-items: baseline;
    height: 100%;
  }

  &.--has-filters {

    .ef-container {
      justify-content: space-between;
    }
  }

  // &--search {
  //
  //   .ef-container {
  //     justify-content: space-between;
  //   }
  // }

  &--live {
    background-color: var(--color-accent);

    .ef-submenu__link.--is-current:after {
      bottom: -1px;
      background-color: var(--color-accent-contrast);
    }
  }

  &__districts {
    // @extend %boxed-padding;
    display: flex;
    align-items: baseline;
    padding-right: spacing(6);
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // overflow-x: scroll;

    @include break(desktop-s) {
      //justify-content: center;
    }

    .ef-submenu--variant & {
      padding-top: spacing(2);

      @include break(desktop-s) {
        flex-wrap: wrap;
      }
    }
  }

  &__district {
    color: var(--color-black);
    @include type-scale(300);

    .ef-submenu--search & {
      color: var(--color-white);
    }

    &:not(:last-child) {
      margin-right: spacing(3);

      .ef-submenu--variant & {
        margin-right: 0;
      }
    }

    &:last-child {
      padding-right: spacing(3);

      @include break(desktop-s) {
        padding-right: 0;
      }
    }

    &:nth-child(2) {
      padding-left: calc(#{spacing-no-calc(1.5)} + 16px);
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><polygon fill="#{url-encoded-color($grayscale--medium)}" points="6,13.4 4.6,12 8.6,8 4.6,4 6,2.6 11.4,8"></polygon></svg>');
      background-position: left center;
      background-repeat: no-repeat;

      .ef-submenu--live & {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><polygon fill="rgba(255, 255, 255, .6)" points="6,13.4 4.6,12 8.6,8 4.6,4 6,2.6 11.4,8"></polygon></svg>');
      }

      .ef-submenu--variant & {
        padding-left: 0;
        background-image: none;
      }
    }

    .ef-submenu--variant & {
      @include type-scale(200);
      font-weight: 700;
    }

    &--main {
      margin-right: spacing(1.5) !important;
      // padding-right: calc(#{spacing-no-calc(1.5)} + 16px);
      // background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16"><polygon fill="#{url-encoded-color($grayscale--medium)}" points="6,13.4 4.6,12 8.6,8 4.6,4 6,2.6 11.4,8"></polygon></svg>');
      // background-position: right center;
      // background-repeat: no-repeat;
      font-weight: 700;

      .ais-MenuSelect-select {
        font-weight: 700;
      }

      .ef-submenu--variant & {
        display: none;
        margin-right: 0 !important;
      }
    }

    &--back {
      padding-left: 20px;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewbox="0 0 12 12"%3E%3Cpath fill="#{url-encoded-color($color--red-400)}" d="M6.414,2,5,.586.293,5.293a1,1,0,0,0,0,1.414L5,11.414,6.414,10l-3-3H12V5H3.414Z" /%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-position: left center;
    }

    &.--is-disabled {
      opacity: .5;
      pointer-events: none;
    }

    .ef-submenu--live & {
      color: var(--color-accent-contrast);
    }
  }

  &__link {
    display: block;
    position: relative;
    padding-top: spacing(1.5);
    padding-bottom: spacing(1.5);
    white-space: nowrap;
    @include transition($duration-faster);


    .ef-submenu--variant & {
      margin-right: spacing(2);
      margin-bottom: spacing(2);
      padding: spacing(.5) spacing(2) spacing(.5);
      border-width: 1px;
      border-style: solid;
      border-color: var(--color-black);
      border-radius: spacing(1.75);
      color: var(--color-black) !important;
    }

    .ef-submenu--variant .ef-submenu__district.--is-current & {
      border-color: var(--color-accent);
      background-color: var(--color-accent);
      color: var(--color-white) !important;
    }
    
    .ef-submenu--filters & {
      border-color: var(--color-front);
      color: var(--color-front) !important;
    }

    .ef-submenu__district--highlight & {
      padding-right: spacing(2);
      padding-left: spacing(2);
      background-color: var(--color-accent);
      color: var(--color-accent-contrast);
      font-weight: 700;
    }

    &:hover {

      @include break(desktop-s) {
        color: var(--color-accent);

        .ef-submenu--live &,
        .ef-submenu__district--highlight & {
          color: var(--color-white);
        }

        .ef-submenu--variant & {
          border-color: var(--color-accent);
        }
      }
    }

    &.--is-active {

      .ef-submenu--variant & {
        border-color: var(--color-accent);
        background-color: var(--color-accent);
        color: var(--color-accent-contrast) !important;
      }
    }

    &.--is-current:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
      height: 4px;
      background-color: var(--color-accent);
      animation: ef-submenu-current .4s $ease-out-quad;

      .ef-submenu--variant & {
        display: none;
      }
    }

    &.--is-current:hover {
      color: var(--color-black);

      .ef-submenu--live &,
      .ef-submenu--variant & {
        color: var(--color-white);
      }
    }
  }

  &__label {
    display: block;
    position: relative;
    padding-top: spacing(1.5);
    padding-bottom: spacing(1.5);
    white-space: nowrap;
  }

  &__filters {
    display: flex;
  }

  &__reset {
    cursor: pointer;
    color: var(--tone-back-300);
    @include type-scale(300);
  }
}
